@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.typing-effect {
  width: 12.5em;
  white-space: nowrap;
  overflow: hidden;
  border-right: .15em solid white;  /* Farbe des Cursors auf Weiß geändert */
  text-align: center;
  margin: 0 auto;
  animation: typing 3.5s steps(40, end), blink-caret .75s step-end infinite;
}

.typing-effect-mobile {
  width: 12.5em;
  white-space: nowrap;
  overflow: hidden;
  border-right: .15em solid white;  /* Farbe des Cursors auf Weiß geändert */
  text-align: center;
  margin: 0 auto;
  margin-top: 3em;
  animation: typing 3.5s steps(40, end), blink-caret .75s step-end infinite;
}

@keyframes typing {
  from { width: 0 }
  to { width: 12em }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: white; }  /* Farbe des Cursors auf Weiß geändert */
}

.shadow-top {
  box-shadow: 0 -10px 15px -10px rgba(0, 0, 0, 0.2);
}
